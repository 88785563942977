import {axiosDefault} from '../lib/network';
import {jsDateToISODateString} from "../lib/dateTimeUtils";

export const getProductionRecords = () => {
  return axiosDefault.get(`/v2/productions/aggregated`).then(function (response) {
    return response.data;
  });
};

export const importC5Productions = () => {
  return axiosDefault.post(`/v2/c5/import-productions`).then(function (response) {
    return response.data;
  });
};

export const generateExtrasBookingReport = ({from, to}) => {
  const fromDate = jsDateToISODateString(from);
  const toDate = jsDateToISODateString(to);
  return axiosDefault.post(`v2/reports/extras-booking-report`, {
    from: fromDate,
    to: toDate,
  }, {
    responseType: 'blob',
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = `extras-booking-days___${fromDate}__${toDate}.csv`
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('Error downloading CSV:', error);
    });
};
