import moment from "moment-timezone"
import {TIME_ZONE} from '../app/constants';

export const ISO8601_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO8601_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const UVE_DATE_FORMAT = 'ddd DD-MM-YYYY';
export const UVE_DATE_WITHOUT_WEEK_FORMAT = 'DD-MM-YYYY';
export const TIME_FORMAT = 'HH:mm';
export const UVE_DATE_TIME_FORMAT = 'HH:mm ddd DD-MM-YYYY';

/**
 * Return current date time in TIME_ZONE time zone;
 */
export const currentDateTime = () => moment().tz(TIME_ZONE);

/**
 * Convert date with ISO8601_DATE_FORMAT to date with day of week.
 * Example: 2019-07-31 -> Sat 31-08-2019
 */
export const dateToUveFormat = date => moment(date, ISO8601_DATE_FORMAT).format(UVE_DATE_FORMAT);

/**
 * Convert date with ISO8601_DATE_FORMAT to date without day of week.
 * Example: 2019-07-31 -> 31-08-2019
 */
export const dateToUveWithoutWeekFormat = date => moment(date, ISO8601_DATE_FORMAT).format(UVE_DATE_WITHOUT_WEEK_FORMAT);

/**
 * Convert date with ISO8601_DATE_TIME_FORMAT to date time without seconds with day of week.
 * Example: 2019-07-31T23:11:22 -> 23:11 Sat 31-08-2019
 */
export const dateTimeToUveFormat = dateTime => moment(dateTime, ISO8601_DATE_TIME_FORMAT).format(UVE_DATE_TIME_FORMAT);

export const dateTimeToUveTime = dateTime => moment(dateTime).format(TIME_FORMAT)

/**
 * Convert (date: 22-03-2021, time: 23:00) to string with ISO8601 date time format.
 */
export const uveDateWithoutWeekAndTimeToDateTime = (date, time) => {
  const moment = parseFromUveDateWithoutWeekAndTime(date, time);
  return momentToDateTime(moment)
}

export const parseFromNativeDatetime = dateTime => moment(dateTime)

/**
 * Create moment instance from ISO8601_DATE_TIME_FORMAT.
 */
export const parseFromDateTime = dateTime => moment(dateTime, ISO8601_DATE_TIME_FORMAT);

/**
 * Create moment instance from ISO8601_DATE_FORMAT.
 */
export const parseFromDate = dateTime => moment(dateTime, ISO8601_DATE_FORMAT);

/**
 * Create moment instance from UVE_DATE_WITHOUT_WEEK_FORMAT.
 */
export const parseFromUveDateWithoutWeek = date => moment(date, UVE_DATE_WITHOUT_WEEK_FORMAT)

/**
 * Create moment instance from date part with UVE_DATE_WITHOUT_WEEK_FORMAT and time part with TIME_FORMAT
 * Example:
 *  (date: 22-03-2021, time: 23:00) -> moment instance
 */
export const parseFromUveDateWithoutWeekAndTime = (date, time) => {
  const dateTime = date + ' ' + time;
  return moment(dateTime, UVE_DATE_WITHOUT_WEEK_FORMAT + ' ' + TIME_FORMAT)
}

/**
 * Convert date time string with ISO8601_DATE_TIME_FORMAT to string which contains only hours and minutes of given
 * date time (HH:mm)
 */
export const dateTimeToHoursAndMinutes = dateTime => momentToHoursAndMinutes(parseFromDateTime(dateTime));

/**
 * Convert date time string with ISO8601_DATE_TIME_FORMAT to string date string with UVE_DATE_WITHOUT_WEEK_FORMAT format
 */
export const dateTimeToDate = dateTime => parseFromDateTime(dateTime).format(UVE_DATE_WITHOUT_WEEK_FORMAT);

/**
 * Get seconds passed from the start of the day.
 */
export const getSecondsFromStartOfDay = momentDateTime => {
  const mmtMidnight = momentDateTime.clone().startOf('day');

  return momentDateTime.diff(mmtMidnight, 'seconds');
};

/**
 * Convert ISO8601 date to timestamp.
 */
export const dateToTimestamp = date => moment.tz(date, ISO8601_DATE_FORMAT, TIME_ZONE).valueOf();

/**
 * Convert seconds passed from midnight o string with HH:mm format.
 */
export const secondsFromMidnightToHHmm = (secondsFromMidnight) => {
  const hours = Math.floor(secondsFromMidnight / 3600);
  const minutes = Math.floor((secondsFromMidnight % 3600) / 60);
  return `${(hours + '').padStart(2, '0')}:${(minutes + '').padStart(2, '0')}`;
}

/**
 * Convert moment instance to string with ISO8601 date time format.
 * @param momentDateTime
 */
export const momentToDateTime = (momentDateTime) => momentDateTime.format(ISO8601_DATE_TIME_FORMAT);

/**
 * Convert moment instance to string with ISO8601 date format.
 * @param momentDateTime
 */
export const momentToDate = (momentDateTime) => momentDateTime.format(ISO8601_DATE_FORMAT);

/**
 * Convert moment instance to string with HH:mm format.
 */
export const momentToHoursAndMinutes = (momentDateTime) => momentDateTime.format("HH:mm");

/**
 * Convert moment instance to object which contain hour and minute of given date time.
 */
export const momentToHoursAndMinutesValues = (momentDateTime) => ({
    hours: momentDateTime.hours(),
    minutes: momentDateTime.minutes()
});

/**
 * Convert moment instance to UVE string format (example: Sat 31-08-2019)
 */
export const momentToUveDateFormat = momentDateTime =>  momentDateTime.format(UVE_DATE_FORMAT);

export const convertSecondsTo24HrHHMM = sec => {
  const hours = Math.floor(sec / 3600);
  const secondsLeftOverForMinutes = sec - (hours * 3600)
  let minutes = 0

  if (secondsLeftOverForMinutes > 0)
    minutes = Math.round(secondsLeftOverForMinutes) / 60

  return hours + "hrs " + minutes + "min"
}

/**
 * Convert js Date instance to ISO8601 date string
 */
export const jsDateToISODateString = (date) => {
  return momentToDate(moment(date))
}
