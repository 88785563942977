import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Form, Row, Col} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import {generateExtrasBookingReport} from "../api";
import Loader from "react-loader-spinner";


const CustomInput = React.forwardRef(({value, onClick}, ref) => (
    <Form.Control type="text" value={value} onClick={onClick} ref={ref} readOnly/>
));

class ExtrasBookingDaysReport extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      from: null,
      to: null,
      downloading: false,
      error: null
    }
  }

  generateReport = async () => {
    this.setState({
      validationError: null
    });
    if (this.state.from && this.state.to && this.state.from.getTime() > this.state.to.getTime()) {
      this.setState({validationError: "To date should be greater than From date"});
      return;
    }
    this.setState({
      downloading: true
    });
    try {
      await generateExtrasBookingReport({from: this.state.from, to: this.state.to});
    } catch (e) {
      this.setState({error: e.toString})
    }

    this.setState({
      downloading: false
    });

    this.handleClose();
  }

  setFrom = (from) => {
    this.setState({from})
  }

  setTo = (to) => {
    this.setState({to})
  }


  handleClose = () => {
    this.setState({
      from: null,
      to: null,
      error: null,
      validationError: null
    }, this.props.onClose);
  };


  render = () => {
    return (
      <div>
        <Modal show={this.props.showExtrasBookingDaysReport}
               onHide={this.handleClose}
               size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Generate Extras Booking Days Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="align-items-center mb-2">
                <Col xs={1}>
                  <Form.Label className="mb-0">From</Form.Label>
                </Col>
                <Col xs={9}>
                  <DatePicker
                    selected={this.state.from}
                    onChange={this.setFrom}
                    customInput={<CustomInput/>}
                  />
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col xs={1}>
                  <Form.Label className="mb-0">To</Form.Label>
                </Col>
                <Col xs={9}>
                  <DatePicker
                    selected={this.state.to}
                    onChange={this.setTo}
                    customInput={<CustomInput/>}
                  />
                </Col>
              </Row>

              {this.state.error &&
                <Row className="align-items-center" style={{marginTop: '15px'}}>
                  <Col>
                    <div className="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  </Col>
                </Row>
              }
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={async () => await this.generateReport()}
                    disabled={!this.state.from || !this.state.to}>
              {this.state.downloading &&
                <Loader type="ThreeDots" color="#ffffff" height={10} width={50}/>
              }
              {!this.state.downloading &&
                <span>Generate</span>
              }
            </Button>
            <Button variant="secondary"
                    onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}


ExtrasBookingDaysReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  showExtrasBookingDaysReport: PropTypes.bool.isRequired,
};

export default ExtrasBookingDaysReport;
