import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import ExtrasBookingDaysReport from "./ExtrasBookingDaysReport";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showGenerateExtrasBookingDaysReport: false
    }
  }

  showExtrasBookingDaysReport = (show) => {
    this.setState({
      showGenerateExtrasBookingDaysReport: show
    })
  }

  render = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <h4 className="py-2 mb-2">
              <ol className="breadcrumb">
                <li className="active">Productions</li>
              </ol>
            </h4>
          </div>
          <div className="col">
            <p className="text-right mt-2">
            </p>
          </div>

          <div className="col">
            <div style={{float: 'right', marginLeft: '15px'}}>
              <button type="button"
                      className="btn btn-sm btn-primary"
                      onClick={this.props.importC5ProductionsRequest.bind(this)}>
                Import C5 Productions
              </button>
            </div>
            <div style={{float: 'right'}}>
              <button type="button"
                      className="btn btn-sm btn-primary"
                      onClick={this.showExtrasBookingDaysReport.bind(this, true)}
              >
                Extras booking report...
              </button>

            </div>
          </div>

          <div style={{clear: 'both'}}></div>
        </div>
        <ExtrasBookingDaysReport
          showExtrasBookingDaysReport={this.state.showGenerateExtrasBookingDaysReport}
          onClose={this.showExtrasBookingDaysReport.bind(this, false)}/>
      </Fragment>

    )
  }
}

Header.propTypes = {
  importC5ProductionsRequest: PropTypes.func.isRequired
};

export default Header;
